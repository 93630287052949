.App {
  text-align: center;
}

h1 {
  font-weight: 400;
}

h2 {
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

h4 {
  font-weight: 400;
}

h5 {
  font-weight: 400;
}

h6 {
  font-weight: 400;
}


.animated-logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.animated-logo {
  animation: move 15s infinite linear, oscillate 1s infinite linear;
}


@keyframes oscillate {
  0%, 100% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(7deg);
  }
}

.primary-bg {
  background-color: rgb(1, 151, 221);
  border: none;
}


.primary-color {
  color: rgb(1, 151, 221);
}

.primary-bg:hover {
  background-color: rgb(1,120,180);
}

.primary-bg:active {
  background-color: rgb(0,75,110);
}

.primary-bg:focus {
  background-color: rgb(0,75,110);
  border: none;
}

.secondary-bg {
  background-color: rgb(130,130,130);
  border: none;
}

.secondary-bg:hover {
  background-color: rgb(110,110,110);
}

.secondary-bg:active {
  background-color: rgb(90,90,90);
}

.secondary-bg:focus {
  background-color: rgb(90,90,90);
  border: none;
}


.danger-bg {
  background-color: rgb(246, 100, 100);
  border: none;
}

.danger-bg:hover {
  background-color: rgb(221, 1, 1);
}

.danger-bg:active {
  background-color: rgb(200, 1, 1);
}

.danger-bg:focus {
  background-color: rgb(200, 1, 1);
  border: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clickable-icon {
  cursor: pointer;
  color: rgb(1, 151, 221);
}

.clickable-icon:hover {
  color: rgb(0,75,110);
}

.removable-button {
  cursor: pointer; 
  border: 1px solid rgb(1, 151, 221); 
  padding-top: 5px; padding-bottom: 5px; 
  padding-left: 10px; 
  padding-right: 10px; 
  border-radius: 5px;
}

.removable-button:hover {
  background-color: rgb(202, 85, 85);
  border: 1px solid rgb(202, 85, 85);
  color: white;
}

.neumorphic-card {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.7), 
            -5px -5px 10px rgba(200, 200, 200, 0.5);
}

.mild-neumorphic-card {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.4), 
            -5px -5px 10px rgba(200, 200, 200, 0.3);
}


.selectable-shadow {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.4), 
            -5px -5px 10px rgba(200, 200, 200, 0.3);
}

.selectable-shadow:hover {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.2), 
            -5px -5px 10px rgba(200, 200, 200, 0.1);
}


.styled-header {
  color: rgb(1, 151, 221)
}


.search-image {
  border: none;
  cursor: pointer;
}

.search-image:hover {
  border: 1px solid rgb(221, 1, 1);
  opacity: 0.7;
}


.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #eeeeee 8%, #dcdcdc 18%, #eeeeee 33%);
  background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}


.nav-link {
  text-decoration: none;
  cursor: pointer;
  font-size: 1vw;
  color: rgb(0,0,0);
  padding-top: 10px;
}

.nav-link:hover {
  color: rgb(1, 151, 221);
}


.nav-drawer-link {
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  color: rgb(0,0,0);
}

.nav-drawer-link:hover {
  color: rgb(1, 151, 221);
}


.footer-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.footer-text {
  color: white;
  cursor: pointer;
}

.footer-text:hover {
  color: rgb(1, 151, 221);
}


.search-result-card {
  color: black;
  cursor: pointer;
}

.search-result-card:hover {
  color: rgb(1, 151, 221);
}


.clickable-card {
  cursor: pointer;
  color: black;
}

.clickable-card:hover {
  color: rgb(1, 151, 221);
}


.neumorphic-button {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.4), 
            -5px -5px 10px rgba(200, 200, 200, 0.3);
  padding-bottom: 10px;
  padding-top: 20px;
  text-align: center;
  cursor: pointer;
}

.neumorphic-button:hover {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.7), 
            -5px -5px 10px rgba(200, 200, 200, 0.5);
}


.theme-button {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.7), 
            -5px -5px 10px rgba(200, 200, 200, 0.5);
  cursor: pointer;
}

.theme-button:hover {
  box-shadow: 5px 5px 12px rgba(100, 100, 100, 0.4), 
            -5px -5px 10px rgba(200, 200, 200, 0.3);
}


.landing-page-text {
  font-size: 2.5vw;
}

.sign-in-card {
  margin-top: 150px;
  padding-top: 50px;
  padding-bottom: 50px; 
  width: 100%;
}


.directory-card {
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  width: 80%;
  height: 400px;
  background-color: white;
  text-align: center
}


.directory-container {
  margin-top: 0px;
  margin-bottom: 0px;
}



.top-nav-expanded {
  display: block;
}

.top-nav-collapsed {
  display: none;
}


.search-card {
  width: 80%;
  margin: 50px; 
}

.info-card {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}


.sidecard {
  background-color:rgb(240, 240, 240);
  height: 100%;
  min-height: 100vh;
}


.boat-card-image {
  height: 400px;
}


.button-bar-button {
  padding: 10px;
}


.select-template {
  cursor: pointer;
}


.select-field-type {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}

.select-field-type:hover {
  background-color: rgb(230, 230, 230);
}


.client-image {
  border: none;
  cursor: pointer;
}

.client-image:hover {
  border: 1px solid rgb(221, 1, 1);
  opacity: 0.7;
}

.search-client-image {
  border: none;
  cursor: pointer;
}

.search-client-image:hover {
  border: 1px solid rgb(58, 120, 227);
  opacity: 0.7;
}


.content-section {
  padding: 50px;
}


.responsive-col-padding {
  padding-left: 10px;
  padding-right: 10px;
}


.directory-card-container {
  padding: 20px;
}

.footer-section {
  padding: 50px;
}


@media screen and (max-width: 576px) {
  .directory-card {
    height: 300px;
    margin-top: 40px;
  }
  .directory-container {
    margin-top: -190px;
    margin-bottom: 100px;
  }
  .top-nav-expanded {
    display: none;
  }
  .top-nav-collapsed {
    display: block;
  }
  .search-card {
    width: 100%;
    margin: 0px; 
  }
  .info-card {
    width: 100%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .item-card {
    margin-top: 30px;
  }
  .boat-card-image {
    height: 250px;
  }
  .landing-page-text {
    font-size: 5vw;
  }
  .sign-in-card {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .button-bar-button {
    padding: 5px;
    font-size: small;
  }
  .sidecard {
    min-height: 50vh;
    padding-bottom: 50px;
  }
  .content-section {
    padding: 0px;
    padding-top: 50px;
  }
  .responsive-col-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .directory-card-container {
    padding: 0px;
    padding-top: 30px;
  }
  .footer-section {
    padding: 0px;
    padding-top: 30px;
    padding-left: 30px;
  }
  .footer-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

}


@media screen and (max-width: 768px) {
  .directory-card {
    height: 300px;
    margin-top: 40px;
  }
  .directory-container {
    margin-top: -190px;
    margin-bottom: 100px;
  }
  .top-nav-expanded {
    display: none;
  }
  .top-nav-collapsed {
    display: block;
  }
  .search-card {
    width: 100%;
    margin: 0px; 
  }
  .info-card {
    width: 100%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .item-card {
    margin-top: 30px;
  }
  .boat-card-image {
    height: 250px;
  }
  .landing-page-text {
    font-size: 4vw;
  }
  .sign-in-card {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .button-bar-button {
    padding: 5px;
    font-size: small;
  }
  .sidecard {
    min-height: 50vh;
    padding-bottom: 50px;
  }
  .content-section {
    padding: 0px;
    padding-top: 50px;
  }
  .responsive-col-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .directory-card-container {
    padding: 0px;
    padding-top: 30px;
  }
  .footer-section {
    padding: 0px;
    padding-top: 30px;
    padding-left: 30px;
  }
  .footer-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 992px) {
  .directory-card {
    height: 300px;
    margin-top: 40px;
  }
  .directory-container {
    margin-top: -190px;
    margin-bottom: 100px;
  }
  .top-nav-expanded {
    display: none;
  }
  .top-nav-collapsed {
    display: block;
  }
  .search-card {
    width: 100%;
    margin: 0px; 
  }
  .info-card {
    width: 100%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .item-card {
    margin-top: 30px;
  }
  .boat-card-image {
    height: 250px;
  }
  .landing-page-text {
    font-size: 4vw;
  }
  .sign-in-card {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .button-bar-button {
    padding: 5px;
    font-size: small;
  }
  .sidecard {
    min-height: 50vh;
    padding-bottom: 50px;
  }
  .content-section {
    padding: 0px;
    padding-top: 50px;
  }
  .responsive-col-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .directory-card-container {
    padding: 0px;
    padding-top: 30px;
  }
  .footer-section {
    padding: 0px;
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .footer-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}


@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 1400px) {
  
}




