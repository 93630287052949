

.page {
    width: 50vw; 
    height: 71vw;
}


.landscape-page {
    width: 50vw;
    height: 35vw;
}

.report-image-shadow {
    box-shadow: 3px 3px 6px rgba(100, 100, 100, 0.7), 
              -3px -3px 5px rgba(200, 200, 200, 0.5);
  }


  .report-image-shadow:hover {
    box-shadow: 3px 3px 6px rgba(100, 100, 100, 0.2), 
              -3px -3px 5px rgba(200, 200, 200, 0.1);
  }


.report-title {
    font-size: 1.5vw;
}

.report-title-landscape {
    font-size: 1.35vw;
}

.report-subtitle {
    font-size: 1.2vw;
}

.report-subtitle-landscape {
    font-size: 1.08vw;
}

.report-subtitle-sm {
    font-size: 1vw;
}

.report-subtitle-sm-landscape {
    font-size: 0.9vw;
}

.report-text {
    font-size: 0.8vw;
}

.report-text-landscape {
    font-size: 0.72vw;
}

.report-text-sm {
    font-size: 0.6vw;
}

.report-text-sm-landscape {
    font-size: 0.65vw;
}

.requirements-image {
    height: 70%;
}

.requirements-text-container {
    height: 2vw;
    margin-top: 1vw;
}

.requirements-image-container {
    height: 2vw;
    margin-top: 1vw;
}

.editable-section {
    cursor: pointer;
}

.editable-section:hover {
    color: rgb(1, 151, 221);
}

.hero-image {
    opacity: 1;
    cursor: pointer;
    height: 20vw;
}

.hero-image:hover {
    opacity: 0.7;
}


.extra-image {
    opacity: 1;
    height: 10vw;
    cursor: pointer;
}

.extra-image:hover {
    opacity: 0.7;
}


.overview-image {
    height: 7.5vw;
}

.main-image-landscape {
    height: 14vw;
}

.extra-image-landscape {
    height: 5vw;
}

.comparison-image-landscape {
    height: 3.3vw;
}


.report-p-tiny {
    padding: 0.3vw;
}

.report-p-1 {
    padding: 1vw;
}

.report-p-2 {
    padding: 2vw;
}

.report-p-3 {
    padding: 3vw;
}

.report-p-4 {
    padding: 4vw;
}

.report-p-5 {
    padding: 5vw;
}

.report-pl-1 {
    padding-left: 1vw;
}

.report-pl-2 {
    padding-left: 2vw;
}

.report-pl-3 {
    padding-left: 3vw;
}

.report-pl-4 {
    padding-left: 4vw;
}

.report-pl-5 {
    padding-left: 5vw;
}

.report-pr-1 {
    padding-right: 1vw;
}

.report-pr-2 {
    padding-right: 2vw;
}

.report-pr-3 {
    padding-right: 3vw;
}

.report-pr-4 {
    padding-right: 4vw;
}

.report-pr-5 {
    padding-right: 5vw;
}

.report-pt-1 {
    padding-top: 1vw;
}

.report-pt-2 {
    padding-top: 2vw;
}

.report-pt-3 {
    padding-top: 3vw;
}

.report-pt-4 {
    padding-top: 4vw;
}

.report-pt-5 {
    padding-top: 5vw;
}

.report-pb-1 {
    padding-bottom: 1vw;
}

.report-pb-2 {
    padding-bottom: 2vw;
}

.report-pb-3 {
    padding-bottom: 3vw;
}

.report-pb-4 {
    padding-bottom: 4vw;
}

.report-pb-5 {
    padding-bottom: 5vw;
}

.report-m-1 {
    margin: 1vw;
}

.report-m-2 {
    margin: 2vw;
}

.report-m-3 {
    margin: 3vw;
}

.report-m-4 {
    margin: 4vw;
}

.report-m-5 {
    margin: 5vw;
}

.report-ml-1 {
    margin-left: 1vw;
}

.report-ml-2 {
    margin-left: 2vw;
}

.report-ml-3 {
    margin-left: 3vw;
}

.report-ml-4 {
    margin-left: 4vw;
}

.report-ml-5 {
    margin-left: 5vw;
}

.report-mr-1 {
    margin-right: 1vw;
}

.report-mr-2 {
    margin-right: 2vw;
}

.report-mr-3 {
    margin-right: 3vw;
}

.report-mr-4 {
    margin-right: 4vw;
}

.report-mr-5 {
    margin-right: 5vw;
}

.report-mt-1 {
    margin-top: 1vw;
}

.report-mt-2 {
    margin-top: 2vw;
}

.report-mt-3 {
    margin-top: 3vw;
}

.report-mt-4 {
    margin-top: 4vw;
}

.report-mt-5 {
    margin-top: 5vw;
}

.report-mb-1 {
    margin-bottom: 1vw;
}

.report-mb-2 {
    margin-bottom: 2vw;
}

.report-mb-3 {
    margin-bottom: 3vw;
}

.report-mb-4 {
    margin-bottom: 4vw;
}

.report-mb-5 {
    margin-bottom: 5vw;
}


.report-br-1 {
    border-radius: 1vw;
}

.report-br-2 {
    border-radius: 2vw;
}

.report-br-3 {
    border-radius: 3vw;
}



.report-button-bar {
    width: 50vw;
}


.template-section-full-height {
    height: 34vw;
}

.template-section-half-height {
    height: 17vw;
}

.template-section {
    background-color: white;
    cursor: pointer;
}

.template-section:hover {
    background-color: rgb(240, 240, 240);
}


@media screen and (max-width: 576px) {
    .requirements-image {
        margin-top: -4.5vw;
    }
}


@media screen and (min-width: 576px) and (max-width) {
    .requirements-image {
        margin-top: -3vw;
    }
}


@media screen and (min-width: 768px) and (max-width: 992px) {
    .requirements-image {
        margin-top: -1vw;
    }
}






@media screen and (max-width: 992px) {
    .page {
        width: 90vw; 
        height: 135vw;
    }

    .landscape-page {
        width: 90vw; 
        height: 63vw;
    }

    .hero-image {
        height: 38vw;
    }

    .extra-image {
        height: 19vw;
    }

    .report-title {
        font-size: 2.7vw;
    }

    .report-title-landscape {
        font-size: 2.43vw;
    }

    .report-subtitle {
        font-size: 2.1vw;
    }

    .report-subtitle-landscape {
        font-size: 1.89vw;
    }

    .requirements-text-container {
        height: 3.5vw;
        margin-top: 1vw;
    }
    .requirements-image-container {
        height: 3.5vw;
        margin-top: 1vw;
    }

    .report-subtitle-sm {
        font-size: 1.75vw;
    }

    .report-subtitle-sm-landscape {
        font-size: 1.575vw;
    }

    .report-text {
        font-size: 1.3vw;
    }

    .report-text-landscape {
        font-size: 1.17vw;
    }

    .report-text-sm {
        font-size: 1.1vw;
    }

    .report-text-sm-landscape {
        font-size: 1.2vw;
    }

    .template-section-full-height {
        height: 61vw;
    }
    .template-section-half-height {
        height: 30.5vw;
    }

    .overview-image {
        height: 11vw;
    }

    .main-image-landscape {
        height: 28vw;
    }

    .extra-image-landscape {
        height: 10vw;
    }

    .comparison-image-landscape {
        height: 6.6vw;
    }

    .report-p-tiny {
        padding: 0.58vw;
    }

    .report-p-1 {
        padding: 1.9vw;
    }
    .report-p-2 {
        padding: 3.8vw;
    }
    .report-p-3 {
        padding: 5.7vw;
    }
    .report-p-4 {
        padding: 7.6vw;
    }
    .report-p-5 {
        padding: 9.5vw;
    }
    .report-pl-1 {
        padding-left: 1.9vw;
    }
    .report-pl-2 {
        padding-left: 3.8vw;
    }
    .report-pl-3 {
        padding-left: 5.7vw;
    }
    .report-pl-4 {
        padding-left: 7.6vw;
    }
    .report-pl-5 {
        padding-left: 9.5vw;
    }
    .report-pr-1 {
        padding-right: 1.9vw;
    }
    .report-pr-2 {
        padding-right: 3.8vw;
    }
    .report-pr-3 {
        padding-right: 5.7vw;
    }
    .report-pr-4 {
        padding-right: 7.6vw;
    }
    .report-pr-5 {
        padding-right: 9.5vw;
    }
    .report-pt-1 {
        padding-top: 1.9vw;
    }
    .report-pt-2 {
        padding-top: 3.8vw;
    }
    .report-pt-3 {
        padding-top: 5.7vw;
    }
    .report-pt-4 {
        padding-top: 7.6vw;
    }
    .report-pt-5 {
        padding-top: 9.5vw;
    }
    .report-pb-1 {
        padding-bottom: 1.9vw;
    }
    .report-pb-2 {
        padding-bottom: 3.8vw;
    }
    .report-pb-3 {
        padding-bottom: 5.7vw;
    }
    .report-pb-4 {
        padding-bottom: 7.6vw;
    }
    .report-pb-5 {
        padding-bottom: 9.5vw;
    }
    .report-m-1 {
        margin: 1.9vw;
    }
    .report-m-2 {
        margin: 3.8vw;
    }
    .report-m-3 {
        margin: 5.7vw;
    }
    .report-m-4 {
        margin: 7.6vw;
    }
    .report-m-5 {
        margin: 9.5vw;
    }
    .report-ml-1 {
        margin-left: 1.9vw;
    }
    .report-ml-2 {
        margin-left: 3.8vw;
    }
    .report-ml-3 {
        margin-left: 5.7vw;
    }
    .report-ml-4 {
        margin-left: 7.6vw;
    }
    .report-ml-5 {
        margin-left: 9.5vw;
    }
    .report-mr-1 {
        margin-right: 1.9vw;
    }
    .report-mr-2 {
        margin-right: 3.8vw;
    }
    .report-mr-3 {
        margin-right: 5.7vw;
    }
    .report-mr-4 {
        margin-right: 7.6vw;
    }
    .report-mr-5 {
        margin-right: 9.5vw;
    }
    .report-mt-1 {
        margin-top: 1.9vw;
    }
    .report-mt-2 {
        margin-top: 3.8vw;
    }
    .report-mt-3 {
        margin-top: 5.7vw;
    }
    .report-mt-4 {
        margin-top: 7.6vw;
    }
    .report-mt-5 {
        margin-top: 9.5vw;
    }
    .report-mb-1 {
        margin-bottom: 1.9vw;
    }
    .report-mb-2 {
        margin-bottom: 3.8vw;
    }
    .report-mb-3 {
        margin-bottom: 5.7vw;
    }
    .report-mb-4 {
        margin-bottom: 7.6vw;
    }
    .report-mb-5 {
        margin-bottom: 9.5vw;
    }
    .report-br-1 {
        border-radius: 1.9vw;
    }
    .report-br-2 {
        border-radius: 3.8vw;
    }
    .report-br-3 {
        border-radius: 5.7vw;
    }
    .report-button-bar {
        width: 90vw;
    }
}
  
  
