.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-content {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  transition: transform 0.2s ease-in-out;
}

.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
